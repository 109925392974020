<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Vehicle Details"
      :showback="true"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <template v-if="vehicle">
      <v-row dense>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
        >
          <template>
            <a
              :href="vehicle.vdp_url"
              target="_blank"
              style="text-decoration: none"
            >
              <v-card
                max-width="400"
              >

                <v-img
                  class="white--text align-end"
                  height="200px"
                  :src="vehicle.image_url"
                >
                <!-- <v-card-title>SOLD</v-card-title> -->
                </v-img>
                <v-card-subtitle class="pb-0">
                  # {{ vehicle.stock_number }}
                </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div>{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.trim }} ( {{ formatNumber(vehicle.vehicle_photo_count) }} photos)</div>
                </v-card-text>
              </v-card>
            </a>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <base-material-chart-card
            :data="vehicle.price_change_histogram"
            color="light-blue darken-1"
            type="Line"
          >
            <h3 class="card-title font-weight-light mt-2 ml-2">
              Price History
            </h3>

            <p class="d-inline-flex font-weight-light ml-2 mt-3">
              <br>
            </p>
          </base-material-chart-card>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="5"
          xl="5"
        >
          <base-material-chart-card
            :data="vehicle.vdp_views_histogram"
            color="green"
            type="Line"
          >
            <h3 class="card-title font-weight-light mt-2 ml-2">
              VDP Views
            </h3>

            <p class="d-inline-flex font-weight-light ml-2 mt-3">
              <br>
            </p>
          </base-material-chart-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <template>
            <v-tabs
              fixed-tabs
              background-color="blue darken-2"
              dark
              :show-arrows="true"
            >
              <v-tab>
                Overview
              </v-tab>
              <v-tab>
                <template v-if="vehicle.similar_vehicles_active.length > 0">
                  <v-badge
                    color="green"
                    :content="vehicle.similar_vehicles_active.length"
                  >
                    Similar Vehicles
                  </v-badge>
                </template>
                <template v-else>
                  Similar Vehicles
                </template>
              </v-tab>
              <v-tab>
                <template v-if="vehicle.similar_vehicles_sold.length > 0">
                  <v-badge
                    color="green"
                    :content="vehicle.similar_vehicles_sold.length"
                  >
                    Similar Sold Vehicles
                  </v-badge>
                </template>
                <template v-else>
                  Similar Sold Vehicles
                </template>
              </v-tab>
              <v-tab>
                Timeline
              </v-tab>
              <v-tab>
                <template v-if="vehicle.vehicle_sale_records.length > 0">
                  <v-badge
                    color="green"
                    :content="vehicle.vehicle_sale_records.length"
                  >
                    Deals
                  </v-badge>
                </template>
                <template v-else>
                  Deals
                </template>
              </v-tab>
              <v-tab>
                Logs
              </v-tab>
              <v-tab-item>
                <v-card
                  flat
                  class="my-0"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                      xl="4"
                    >
                      <v-card-text>
                        <base-material-stats-card
                          :color="getAgeColor(vehicle.cached_age, alertSettings)"
                          icon="mdi-clock-time-eight"
                          title="Age"
                          :value="`${vehicle.cached_age} Days`"
                        />
                      </v-card-text>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                      xl="4"
                    >
                      <v-card-text>
                        <base-material-stats-card
                          :color="getPriceColor(vehicle.dealer_cost)"
                          icon="mdi-calculator"
                          title="Cost"
                          :value="`${formatPrice(vehicle.dealer_cost)}`"
                        />
                      </v-card-text>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                      xl="4"
                    >
                      <v-card-text>
                        <base-material-stats-card
                          :color="getPriceColor(vehicle.price)"
                          icon="mdi-currency-usd"
                          title="Price"
                          :value="`${formatPrice(vehicle.price)}`"
                        />
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                      xl="4"
                    >
                      <v-card-text>
                        <base-material-stats-card
                          color="blue lighten-1"
                          icon="mdi-chart-bell-curve-cumulative"
                          title="Avg Daily Page Views"
                          :value="`${formatNumberToString(vehicle.average_daily_page_views)}`"
                        />
                      </v-card-text>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                      xl="4"
                    >
                      <v-card-text>
                        <base-material-stats-card
                          :color="getDaysSincePriceChangeColor(vehicle.days_since_last_price_change, alertSettings)"
                          icon="mdi-target"
                          title="Price Changes"
                          :value="`${formatNumberToString(vehicle.price_changes_count)}`"
                          :to="{ name: 'Vehicle Price Changes', params: { vehicle_id: vehicle.id } }"
                        />
                      </v-card-text>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="4"
                      xl="4"
                    >
                      <v-card-text>
                        <base-material-stats-card
                          :color="getLeadColor(vehicle.lead_count, alertSettings)"
                          icon="mdi-human-greeting"
                          title="Leads"
                          :value="`${formatNumberToString(vehicle.lead_count)}`"
                          :to="{ name: 'Vehicle Leads', params: { vehicle_id: vehicle.id } }"
                        />
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card
                  flat
                  class="my-0"
                >
                  <v-card-text>
                    <p>
                      Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits.
                    </p>

                    <p>
                      Dramatically visualize customer directed convergence without revolutionary ROI. Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits.
                    </p>

                    <div>This is very nice.</div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card
                  flat
                  class="my-0"
                >
                  <v-card-text>
                    <p>
                      Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits.
                    </p>

                    <p>
                      Dramatically visualize customer directed convergence without revolutionary ROI. Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits.
                    </p>

                    <div>This is very nice.</div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <template>
                  <v-timeline>
                    <v-timeline-item
                      v-for="(event, i) in vehicle.timeline"
                      :key="i"
                      :color="event.color"
                      small
                    >
                      <template v-slot:opposite>
                        <span
                          :class="`headline font-weight-bold ${event.color}--text`"
                          v-text="event.date"
                        />
                      </template>
                      <v-card class="elevation-2">
                        <v-card-text>{{ event.event_title }}</v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </template>
              </v-tab-item>
            </v-tabs>
          </template>
        </v-col>
      </v-row>
    </template>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import VehicleService from '@/services/VehicleService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { countColorsMixin } from '@/mixins/countColorsMixin.js'

  export default {
    name: 'VehicleDetails',
    mixins: [formatterMixin, countColorsMixin],
    data () {
      return {
        items: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        singleExpand: false,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'On Website', value: 'stats_hash.on_website' },
          { text: 'With Photos', value: 'stats_hash.with_photos' },
          { text: 'In Stock', value: 'stats_hash.in_stock' },
          { text: 'Sold', value: 'stats_hash.sold' },
          { text: '% of Inventory', value: 'stats_hash.percent_of_inventory' },
          { text: '% of Sold', value: 'stats_hash.percent_of_sold' },
        ],
        years: [
          {
            color: 'cyan',
            year: '1960',
          },
          {
            color: 'green',
            year: '1970',
          },
          {
            color: 'pink',
            year: '1980',
          },
          {
            color: 'amber',
            year: '1990',
          },
          {
            color: 'orange',
            year: '2000',
          },
        ],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_vehicle_detail.csv`
      },
      ...mapGetters(['accountId', 'alertSettings', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadGroups()
      },
    },
    created () {
      this.loadGroups()
    },
    methods: {
      loadGroups: function () {
        this.loading = false
        this.items = []
        this.vehicle = null
        this.loading = true
        VehicleService.getVehicle(this.$route.params.vehicle_id)
          .then(response => {
            this.vehicle = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
    },
  }
</script>
